import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout/layout"
import SceneIndex from "../components/product/sibplay/scenes/sceneindex"
import { connect } from "react-redux"
import PrivateContent from "../components/user/privatecontent"

const SceneIndexContainer = (props) => {
  const playId = props.pageContext.playId
  const playTitle = props.pageContext.playTitle
  const manifest = props.pageContext.manifest
  let thumbnailMappings = []
  for (var edge of props.data.thumbnails.edges) {
    let node = edge.node
    let sceneId = node.relativePath.substring(
      node.relativePath.length - 13,
      node.relativePath.length - 4
    )
    thumbnailMappings[sceneId] = {
      normal: node.childImageSharp.gatsbyImageData,
      greyed: null
    }
  }
  for (var edge2 of props.data.thumbnails_greyscale.edges) {
    let node = edge2.node
    let sceneId = node.relativePath.substring(
      node.relativePath.length - 13,
      node.relativePath.length - 4
    )
    thumbnailMappings[sceneId] = {
      ...thumbnailMappings[sceneId],
      greyed: node.childImageSharp.gatsbyImageData,
    }
  }
 
  return (
    <Layout>
      <PrivateContent accessLevel="requireslogin">
        <SceneIndex
          playId={playId}
          playTitle={playTitle}
          manifest={manifest}
          thumbnailMappings={thumbnailMappings}
          subInfo={props.subInfo}
        />
      </PrivateContent>
    </Layout>
  )
}

export const query = graphql`
  query ThumbnailImages($thumbnailRegex: String) {
    thumbnails: allFile(filter: { relativePath: { regex: $thumbnailRegex } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, transformOptions: { })
          }
        }
      }
    }
    thumbnails_greyscale: allFile(
      filter: { relativePath: { regex: $thumbnailRegex } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              transformOptions: { grayscale: true }
            )
          }
        }
      }
    }
  }
`

export default connect(
  (state) => ({
    lastKps: state.app.lastKps,
  }),
  null
)(SceneIndexContainer)
