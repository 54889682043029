import React from "react"
import { Link } from "gatsby"
import PlayNav from "../playnav/playnav"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../../common/layout/seo"
import NoSubBanner from "../../../common/misc/nosubbanner"
import { connect } from "react-redux"

import HighlightSVG from "../../../../scss/img/icon/sibsvg/sib-icon-eye.svg"

const SceneIndex = (props) => {
  var isUnpaid = props.subInfo && props.subInfo.isUnpaid
  const unpaidText = `Your trial account gives you access to select scenes. Subscribe
  for full access to all plays!`

  return (
    <>
      <Seo title={props.playTitle} />
      <div className="block">
        <PlayNav
          playId={props.playId}
          playTitle={props.playTitle}
          section="sceneindex"
          showSceneNav={false}
          manifest={props.manifest}
        />
      </div>
      <NoSubBanner isUnpaid={isUnpaid} text={unpaidText} />
      <div className="container scene-listing-container">
        <SceneListing
          manifest={props.manifest}
          thumbnailMappings={props.thumbnailMappings}
          playId={props.playId}
          lastKps={props.lastKps}
          isUnpaid={isUnpaid}
        />
      </div>
    </>
  )
}

const SceneListing = (props) => {
  var index = 0
  return Object.keys(props.manifest.acts).map((key) => {
    let scenes = props.manifest.acts[key].scenes.map((scene) => {
      if (props.isUnpaid && scene.access === "requirespaidsub") {
        return (
          <a href="/pricing" key={`key_index_${index++}`}>
            <SceneTileCol scene={scene} {...props} />
          </a>
        )
      } else {
        return (
          <Link
            to={"/plays/" + props.playId + "/" + scene.sceneId + "_SX001"}
            key={`key_index_${index++}`}
          >
            <SceneTileCol scene={scene} {...props} />
          </Link>
        )
      }
    })
    return (
      <div
        className={`${key % 2 === 1 ? "" : ""}`}
        key={props.manifest.acts[key].name}
      >
        <div className="has-text-centered is-hidden-tablet">
          <h4 className="title is-4">{props.manifest.acts[key].name}</h4>
        </div>
        <div className="is-hidden-mobile">
          <h4 className="title is-4">{props.manifest.acts[key].name}</h4>
        </div>
        <div className="columns is-multiline scene-column my-2">{scenes}</div>
      </div>
    )
  })
}

const SceneTileCol = (props) => {
  return (
    <div className="column" key="{scene.sceneId}">
      <div className="is-hidden-mobile" style={{ width: "250px" }}>
        <SceneTile {...props} />
      </div>
      <div className="is-hidden-tablet">
        <SceneTile {...props} />
      </div>
    </div>
  )
}

const SceneTile = (props) => {
  var isThisScene = false

  if (props.lastKps[props.playId]) {
    let lastKpSceneId = props.lastKps[props.playId].slice(0, 9) // === scene.sceneId
    if (lastKpSceneId === props.scene.sceneId) {
      isThisScene = true
    }
  }

  let greyedOut = props.isUnpaid && props.scene.access === "requirespaidsub"

  var imageDisplay = props.thumbnailMappings[props.scene.sceneId].normal
  if (greyedOut) {
    imageDisplay = props.thumbnailMappings[props.scene.sceneId].greyed
  }

  return (
    <div
      className={`scene-index-entry-frame ${
        isThisScene
          ? "scene-index-entry-frame-selected"
          : "scene-index-entry-frame"
      }`}
    >
      <h5
        className={`title is-6 mb-1 ${
          greyedOut ? "has-text-sibgrey4base" : "has-text-sibblack"
        }`}
      >
        <div className={`has-text-centered is-hidden-tablet`}>
          {props.showAct ? props.scene.actName + " - " : ""}
          {props.scene.sceneName}
        </div>
        <div className={`is-hidden-mobile`}>
          {props.showAct ? props.scene.actName + " - " : ""}
          {props.scene.sceneName}
        </div>
      </h5>
      <div className="selected-scene-wrapper">
        <div
          className={`selected-scene-icon ${isThisScene ? "" : "is-hidden"}`}
        >
            <HighlightSVG fill="#ff3a00" width="75" height="75" />
        </div>
        <GatsbyImage
          image={imageDisplay}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
      </div>
      <div
        className={`has-text-left my-1 ${
          greyedOut ? "has-text-sibgrey4base" : "has-text-sibblack"
        }`}
      >
        <div className="has-text-centered is-hidden-tablet">
          {props.scene.thumbnailDesc}
        </div>
        <div className="is-hidden-mobile">{props.scene.thumbnailDesc}</div>
      </div>
    </div>
  )
}

export default connect(
  (state) => ({
    lastKps: state.app.lastKps,
  }),
  null
)(SceneIndex)
