import React from "react"
import PageBanner from "../common/misc/pagebanner"
import Loader from "react-loader-spinner"

const CheckingAccess = () => {
  return (
    <>
      <PageBanner title="One Moment Please ..." />
      <div className="section my-1">
        <div className="has-text-centered my-2">
          <Loader type="TailSpin" color="#000000" height={100} width={100} />
        </div>
        <div className="title is-5 has-text-centered mt-3">
          Checking Account Details... Please Wait
        </div>
      </div>
    </>
  )
}

export default CheckingAccess
