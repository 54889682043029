import React from "react"

import PlaySVG from "../../../../scss/img/icon/sibsvg/sib-icon-theatre.svg"
import ScenesSVG from "../../../../scss/img/icon/sibsvg/sib-icon-clapperboard.svg"
import CastSVG from "../../../../scss/img/icon/sibsvg/sib-icon-crown.svg"
import AnalysisSVG from "../../../../scss/img/icon/sibsvg/sib-icon-clipboard.svg"

const ButtonBarPlayNav = (props) => {

  const buttonDefs = [
    {
      label: "Play",
      value: "theplay",
      image: PlaySVG,
    },
    {
      label: "Scenes",
      value: "sceneindex",
      image: ScenesSVG,
    },
    {
      label: "Cast",
      value: "cast",
      image: CastSVG,
    },
    {
      label: "Analysis",
      value: "analysis",
      image: AnalysisSVG,
    },
  ]

  return (
    <div
      className="play-nav-button-frame columns is-centered is-mobile px-1 mx-1"
      style={{ cursor: "pointer" }}
    >
      {buttonDefs.map((buttonDef) => {
        return (
          <ButtonBarButton
            key={buttonDef.label + buttonDef.value}
            gotoSection={props.gotoSection}
            buttonDef={buttonDef}
            currentSection={props.section}
          />
        )
      })}
      <div className="divider is-vertical my-1"></div>
      <div className="divider is-vertical my-1"></div>
    </div>
  )
}

const ButtonBarButton = (props) => {
  const isCurrentSection = props.currentSection === props.buttonDef.value
  return (
    <>
      <div className="divider is-vertical my-1"></div>
      <div className="divider is-vertical my-1"></div>
      <div
        className={`column px-0 mt-1 ${
          isCurrentSection ? "play-nav-button-outer-selected" : ""
        }`}
        tabIndex={0}
        role="button"
        onClick={(e) => props.gotoSection(props.buttonDef.value)}
        onKeyDown={(e) => props.gotoSection(props.buttonDef.value)}
      >
        <div
          className={`play-nav-button-svg-wrapper ${isCurrentSection ? "is-hidden" : ""}`}
        >
          {React.createElement(props.buttonDef.image, {})}
        </div>
        <div className={`play-nav-button-svg-wrapper ${isCurrentSection ? "" : "is-hidden"}`}>
          {React.createElement(props.buttonDef.image, { fill: `#ff3a00` })}
        </div>
        <div className="label has-text-centered">
          <span
            className={`is-size-6 ${
              isCurrentSection ? "has-text-sibredorange" : "has-text-black"
            }`}
          >
            {props.buttonDef.label}
          </span>
        </div>
      </div>
    </>
  )
}

export default ButtonBarPlayNav
