import React from "react"
import { Link } from "gatsby"

import LockSVG from "../../../scss/img/icon/sibsvg/sib-icon-lock.svg"

const NoSubBanner = (props) => {
  return (
    <div className={`container mb-3 ${props.isUnpaid ? "" : "is-hidden"}`}>
      <div
        className={`notification unpaid-notification has-background-sibnotificationbackground`}
      >
        <div className="columns is-centered is-vcentered">
          <div className="column is-1">
            <div className="has-text-centered">
            <LockSVG/>
            </div>
          </div>
          <div className="column">
            <div className="has-text-centered-mobile">
            <span className="has-text-7">
              {props.text}
            </span>
            </div>
          </div>
          <div className="column is-2 has-text-centered">
            <Link className="button is-black" to="/pricing">
              Subscribe!
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoSubBanner
