import React from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import ButtonBarPlayNav from "./buttonbarplaynav"

class PlayNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.sectionTitle = this.getSectionTitle.bind(this)
    this.gotoSection = this.gotoSection.bind(this)
  }

  getSectionTitle() {
    switch (this.props.section) {
      case "theplay":
        return "The Play"
      case "sceneindex":
        return "Scene Index"
      case "cast":
        return "The Cast"
      case "analysis":
        return "Text & Analysis"
      default:
        return ""
    }
  }

  gotoSection(value) {
    let basePath = "/plays/" + this.props.playId + "/"
    switch (value) {
      case "theplay": {
        if (this.props.lastKps[this.props.playId] != null) {
          navigate(basePath + this.props.lastKps[this.props.playId])
        } else {
          if (this.props.hideNextScene) {
            navigate(basePath + this.props.manifest.firstKeypageId)
          } else {
            navigate(
              basePath + this.props.manifest.freeScenes[0].sceneId + "_SX001"
            )
          }
        }
        break
      }
      case "sceneindex":
        navigate(basePath + "scenes")
        break
      case "cast":
        if (this.props.lastChars[this.props.playId] != null) {
          navigate(basePath + "cast/" + this.props.lastChars[this.props.playId])
        } else {
          navigate(basePath + "cast/" + this.props.manifest.firstCharId)
        }
        break
      case "analysis":
        if (this.props.lastSupps[this.props.playId] != null) {
          navigate(basePath + "supp/" + this.props.lastSupps[this.props.playId])
        } else {
          navigate(basePath + "supp/plot")
        }
        break
      default:
        return ""
    }
  }

  render() {
    return (
      <div className="play-nav-outer section has-background-sibgrey2lighter py-0">
        <div className="container py-0">
          <div className="columns is-vcentered my-0">
            <div className="column has-text-centered-mobile">
              <h1 className="title">{this.props.playTitle}</h1>
            </div>
            <div className="column pr-0">
              <ButtonBarPlayNav
                getSectionTitle={this.getSectionTitle}
                gotoSection={this.gotoSection}
                section={this.props.section}
              ></ButtonBarPlayNav>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    lastKps: state.app.lastKps,
    lastChars: state.app.lastChars,
    lastSupps: state.app.lastSupps,
  }),
  null
)(PlayNav)
