/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"

import CheckingAccess from "./checkingaccess"
import { useSubInfoContext } from "../../services/subinfocontext"
import { navigate } from "gatsby"

const PrivateContent = props => {
  const subInfoContext = useSubInfoContext()
  const [showPage, setShowPage] = useState(false)
  const timer = useRef(null)

  const bugOut = () => {
    navigate("/login", {state: {showError:true}})
  }

  const checkAccess = () => {
    if (props.accessLevel === "unauthenticated") {
      setShowPage(true)
      return
    }
    if (props.accessLevel === "student") {
      if (subInfoContext.subInfo && subInfoContext.subInfo.isStudentCode) {
        setShowPage(true)
        return
      }
    }
    // Check sub info
    if (props.accessLevel === "requireslogin"){
      if (!subInfoContext.subInfo) {
        return
      } else {
        setShowPage(true)
      }
    }
    if (props.accessLevel === "requirespaidsub") {
      if (subInfoContext.subInfo && subInfoContext.subInfo.isStudentCode) {
        setShowPage(true)
        return
      } else {
        if (
          subInfoContext.subInfo &&
          subInfoContext.subInfo.subInfo.status === "paid" &&
          (new Date(subInfoContext.subInfo.subInfo.expiry) > new Date() || !subInfoContext.subInfo.subInfo.expiry)
        ) {
          setShowPage(true)
          return
        } else {
          return
        }
      }
    }
  }

  useEffect(() => {
    if (subInfoContext.subInfoLoaded) {
      checkAccess()
    }
  }, [subInfoContext.subInfoLoaded])

  // Only in the case where there's no login info at all
  useEffect(() => {
    // Time out if user hasn't seen any login info or activity in 5 seconds
    if (!showPage) {
      timer.current = setTimeout(() => bugOut(), 5000)
    } else {
      clearInterval(timer.current)
    }
    return() => {
      clearInterval(timer.current)
    }

  }, [showPage])

  var subInfo = subInfoContext.subInfo
  if (subInfo) {
    subInfo['isUnpaid'] = false
    subInfo['isExpired'] = false
    subInfo['isStudent'] = subInfo.isStudentCode
    if (subInfo.subInfo) {
      subInfo.isUnpaid =
        subInfo.subInfo.status !== "paid" ||
        (subInfo.subInfo.expiry &&
          new Date(subInfo.subInfo.expiry) < new Date())
      if (
        subInfo.subInfo.expiry &&
        new Date(subInfo.subInfo.expiry) < new Date()
      ) {
        subInfo.isExpired = true
      }
    }
  }
  
  let retProps = {
    subInfo: subInfo,
    ...props,
  }

  return !showPage ? (
    <CheckingAccess />
  ) : (
    React.cloneElement(props.children, retProps)
  )
}

export default PrivateContent
