import React from "react"

const PageBanner = (props) => {
  return (
    <div className="section has-background-sibgrey2lighter py-2 mb-1">
    <div className="container py-0">
      <div className="columns is-vcentered my-0">
        <div className="column has-text-centered-mobile">
          <h1 className="title">{props.title}</h1>
        </div>
        <div className="column has-text-right-tablet has-text-centered-mobile">
            <h5 className="is-5 has-text-weight-bold has-text-sibgrey6darker">{props.userGreet}</h5>
        </div>
      </div>
    </div>
    </div>
  )
}

export default PageBanner
